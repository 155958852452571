<template>
  <MainNavDropdownList class="w-88">
    <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
    <MainNavDropdownListLink
      v-for="item in navItems.items"
      :key="item.name"
      :href="item.route"
    >
      <div class="flex gap-4">
        <keep-alive>
          <component
            :is="item.icon"
            class="nav-menu__icon"
          />
        </keep-alive>
        <div>
          <header class="font-bold text-base text-ol-dark-ink-200">
            {{ item.name }}
          </header>
          <p class="text-dark-pewter-50">
            {{ item.description }}
          </p>
        </div>
      </div>
    </MainNavDropdownListLink>
  </MainNavDropdownList>
</template>

<script setup>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import {
  HeartIcon,
  ScaleIcon,
  PhoneIcon
} from '@heroicons/vue/24/outline';

const navItems = {
  title: 'Get support after loss',
  items: [
    {
      name: 'Bereavement Support Hubs',
      description: 'Build a supportive community',
      icon: HeartIcon,
      route: route('bereavement'),
    }, {
      name: 'Probate',
      description: 'Your guide through probate',
      icon: ScaleIcon,
      route: route('probate'),
    },
    {
        name: 'Dedicated Concierge',
        description: 'Personal support after loss',
        icon: PhoneIcon,
        route: route('death-concierge'),
      },
  ],
}
</script>

<style lang="scss" scoped>
.nav-menu__icon {
  @apply my-auto h-6 flex-shrink-0 flex-grow-0;
}
</style>
