<template>
  <div v-if="!hidden" class="bg-white whitespace-nowrap text-ol-dark-ink-200">
    <a
      :href="appRoute('account-settings.show')"
      class="flex group py-2 transition-colors duration-150 ease-in-out px-2 lg:px-0 group"
    >
      <div class="flex items-center truncate">
        <AvatarIcon size="medium" class="w-12 h-12" />
        <div class="ml-2 truncate">
          <div class="text-lg font-medium truncate">
            <span class="group-hover:underline">
              {{ name }}
            </span>
          </div>
        </div>
      </div>
    </a>

    <div class="mt-2 pb-32 lg:pb-0 justify-start">
      <a
        class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        :href="appRoute('support-hubs.index')"
        data-testid="nav-profile-support-hubs-link"
      >
        My Support Hubs
      </a>
      <a
        class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        :href="appRoute('will-basic.onboarding-welcome')"
        data-testid="nav-profile-will-link"
      >
        My Will
      </a>
      <template v-if="isAuthenticated && dashboardHash">
        <a
          :href="dashboardUrl"
          class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        >
          {{ dashboardText }}
        </a>
        <a
          v-if="!dashboardIsEmployer"
          :href="route('charity.data-portal.show', dashboardHash)"
          class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        >
          Data Portal
        </a>
      </template>
      <a
        class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        :href="appRoute('account-settings.show')"
        data-testid="nav-profile-account-settings-link"
      >
        Account Settings
      </a>
      <a
        v-if="is_member && hasBankWillPdf"
        class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0"
        :href="appRoute('member.dashboard')"
      >
        Member Dashboard
      </a>
      <a
        class="font-medium text-xl pr-16 py-2 block hover:underline px-2 lg:px-0 cursor-pointer"
        data-testid="nav-profile-logout-link"
        @click.prevent="logOutUser"
      >
        Logout
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AvatarIcon from '@components/AvatarIcon.vue';
import { logout } from '@js/services/logout';

export default {
  components: { AvatarIcon },

  props: {
    hidden: Boolean,
  },

  data() {
    return {
      dashboardHash: null,
      dashboardIsEmployer: false,
    };
  },

  created() {
    this.dashboardHash = window.guardianAngel.user.dashboard_hash;
    this.dashboardIsEmployer = window.guardianAngel.user.dashboard_is_employer;
  },

  computed: {
    ...mapState('user', ['name', 'avatar', 'is_member', 'hasBankWillPdf']),
    ...mapGetters('user', ['isAuthenticated']),
    dashboardUrl() {
      if (this.dashboardIsEmployer) {
        return route('employer.dashboard.show', this.dashboardHash);
      } else {
        return route('charity.metric-dashboard.show', this.dashboardHash);
      }
    },
    dashboardText() {
      if (this.dashboardIsEmployer) {
        return 'Employer Dashboard';
      } else {
        return 'Charity Dashboard';
      }
    },
  },

  methods: {
    async logOutUser() {
      await logout();
      window.location.href = window.route('login');
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
