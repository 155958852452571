<template>
  <MainNavDropdownList class="w-88">
    <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
    <MainNavDropdownListLink
      v-for="item in navItems.items"
      :key="item.name"
      :href="item.route"
    >
      <div class="flex gap-4">
        <keep-alive>
          <component
            :is="item.icon"
            class="nav-menu__icon"
          />
        </keep-alive>
        <div>
          <header class="font-bold text-base text-ol-dark-ink-200">
            {{ item.name }}
          </header>
          <p class=" whitespace-pre-wrap text-dark-pewter-50">
            {{ item.description }}
          </p>
        </div>
      </div>
    </MainNavDropdownListLink>
  </MainNavDropdownList>
</template>

<script setup>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import { computed } from 'vue';
import {
  PencilIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  GiftIcon
} from '@heroicons/vue/24/outline';

const willRoute = computed(() => {
  return window.guardianAngel?.user?.hasWill ? route('will-basic.onboarding-welcome') : route('will-writing');
});

const navItems = {
  title: 'Plan for death',
  items: [
    {
      name: 'Will Writing',
      description: 'Write a will that’s tailored to you',
      icon: PencilIcon,
      route: willRoute.value,
    }, {
      name: 'Life Insurance',
      description: 'Life Insurance for the 21st century',
      icon: ShieldCheckIcon,
      route: route('life-insurance'),
    },
    {
      name: 'Lasting Power of Attorney',
      description: 'Appoint someone you trust to make \n' +
        'decisions on your behalf',
      icon: DocumentTextIcon,
      route: route('lasting-power-of-attorney'),
    },
    {
      name: 'Legacy Box',
      description: 'Make life easier for the people you leave behind',
      icon: GiftIcon,
      route: route('fwig'),
    },

  ],
};
</script>

<style lang="scss" scoped>
.nav-menu__icon {
  @apply my-auto h-6 flex-shrink-0 flex-grow-0;
}
</style>
