<template>
  <nav
    :class="[
      { 'h-full-height rounded-none lg:h-auto': statusMobileNav },
      useNew ? 'ol-navbar' : 'navbar',
    ]"
  >
    <div class="navbar__items">
      <span v-if="showOctopusLogo" class="navbar__logo">
        <a :href="appRoute('home')" class="flex lg:block">
          <LogoOctopusLegacy class="h-7" />
        </a>
      </span>
      <button v-else @click="mobileItemIndex = null">
        <span class="flex"> <BackArrow class="stroke-white p-1" />Back</span>
      </button>
      <ul class="navbar__menu">
        <MenuItem
          v-for="item in menu"
          :id="item.id"
          :key="item.id"
          v-model="desktopItemId"
          :title="item.title"
          :content="item.content"
          class="my-auto"
        />
      </ul>
      <BurgerIcon
        class="navbar__burger-icon"
        :open="statusMobileNav"
        @click.native="toggleMobileNav"
      />
      <div class="navbar__right">
        <MainNavCTA />
        <MainNavAvatar v-if="isAuthenticated" />
        <a v-else :href="appRoute('login')" class="navbar__sign-in">
          Sign in
        </a>
      </div>
    </div>
    <div class="navbar__mobile">
      <MobileNav v-if="statusMobileNav" v-model="mobileItemIndex" />
    </div>
  </nav>
</template>

<script setup>
import BackArrow from '@components/Icon/BackArrow.vue';
import BurgerIcon from '@components/MainNav/partials/BurgerIcon.vue';
import LogoOctopusLegacy from '@components/Icon/LogoOctopusLegacy.vue';
import MainNavAvatar from '@components/MainNav/MainNavAvatar.vue';
import MainNavCTA from '@components/MainNav/partials/MainNavCTA.vue';
import MenuItem from '@components/MainNav/MenuItem.vue';
import MobileNav from '@components/MainNav/MobileNav.vue';
import { ScreenSizes } from '@enums';
import { computed, ref } from 'vue';
import { useScreenSize } from '@js/composables/useScreenSizeHelper';
import { useStore } from 'vuex';

const props = defineProps({
  customClasses: {
    type: String,
    default: null,
  },
  useNew: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const isAuthenticated = store.getters['user/isAuthenticated'];

const { screenWidth } = useScreenSize();

const desktopItemId = ref(null);
const mobileItemIndex = ref(null);
const statusMobileNav = ref(false);

const menu = [
  {
    id: 1,
    title: 'About Us',
    dropdown: 'about',
    content: 'AboutNavContent',
  },
  {
    id: 2,
    title: 'Plan for death',
    dropdown: 'eol',
    content: 'EndOfLifeNavContent',
  },
  {
    id: 3,
    title: 'Get support after loss',
    dropdown: 'bereavement',
    content: 'BereavementContent',
  },
];

const showOctopusLogo = computed(
  () =>
    mobileItemIndex.value === null ||
    !statusMobileNav.value ||
    screenWidth > ScreenSizes.lg
);

const toggleMobileNav = () => {
  statusMobileNav.value = !statusMobileNav.value;
  if (statusMobileNav.value === false) {
    mobileItemIndex.value = null;
  }
};
</script>

<style lang="scss" scoped>
nav.navbar,
nav.ol-navbar {
  @apply sticky
    z-30
    top-0
    flex
    flex-col
    justify-center
    items-center
    text-white
    bg-ol-dark-ink-200
    align-middle
    border-b
    border-ol-dark-100;

  .navbar__items {
    @apply flex
      justify-between
      items-center
      w-full
      gap-2
      max-w-content
      p-5
      lg:py-6;
  }

  .navbar__logo {
    @apply justify-start lg:justify-center;
  }

  .navbar__mobile {
    @apply lg:hidden
      w-full
      overflow-y-auto;
  }

  .navbar__sign-in {
    @apply text-xs lg:text-base font-medium text-ol-pink hover:underline;
  }

  .navbar__burger-icon {
    @apply lg:hidden flex justify-end items-center;
  }

  .navbar__right {
    @apply hidden lg:flex lg:gap-x-4 items-center;
  }

  .navbar__menu {
    @apply hidden lg:flex
      lg:gap-4 xl:gap-8;
  }
}

nav.ol-navbar {
  @apply rounded-xl sm:rounded-2xl;
}
</style>
