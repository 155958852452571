<template>
  <div class="nav">
    <div v-if="modelValue === null">
      <ul class="nav__items">
        <li
          v-for="(menu, index) in menus"
          class="nav__items__item"
          @click="() => $emit('update:modelValue', index)"
        >
          {{ menu.title }}
          <ChevronRight class="nav__chevron" />
        </li>
      </ul>
      <DropdownNotSureWhereToStart class="nav__where-to-start" />
    </div>
    <transition>
      <MobileMenuItem
        v-if="modelValue !== null"
        :nav-items="menus[modelValue]"
      />
    </transition>
    <div class="nav__button-group">
      <MainNavCTA />
      <ButtonRounded
        v-if="!isAuthenticated"
        variant="tertiary"
        :size="buttonSize"
        :width="buttonWidth"
      >
        <a :href="route('login')">Sign in </a>
      </ButtonRounded>
    </div>
    <MainNavAccount v-if="isAuthenticated" class="mt-6" />
  </div>
</template>

<script setup>
import ButtonRounded from '../Button/ButtonRounded.vue';
import ChevronRight from '../Icon/ChevronRight.vue';
import DropdownNotSureWhereToStart from './partials/DropdownNotSureWhereToStart.vue';
import MainNavAccount from './MainNavAccount.vue';
import MainNavCTA from './partials/MainNavCTA.vue';
import MobileMenuItem from './MobileMenuItem.vue';
import { ScreenSizes } from '@enums';
import { computed, ref } from 'vue';
import { useScreenSize } from '../../composables/useScreenSizeHelper';
import { useStore } from 'vuex';
import {
  SparklesIcon,
  ChatBubbleLeftEllipsisIcon,
  BriefcaseIcon,
  PhoneIcon,
  PencilIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  GiftIcon,
  HeartIcon,
  ScaleIcon,
} from '@heroicons/vue/24/outline';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
});

const open = ref(false);

let { screenWidth } = useScreenSize();
const showMenuScreen = ref(false);

// prettier-ignore
const buttonSize = computed(() => {
      return screenWidth.value >= ScreenSizes.lg
        ? 'xs'
        : screenWidth.value > ScreenSizes.sm
          ? 'large'
          : 'medium';
    });

const buttonWidth = computed(() => {
  return screenWidth.value > ScreenSizes.sm ? '1/2' : 'full';
});

const willRoute = computed(() => {
  return window.guardianAngel?.user?.hasWill
    ? route('will-basic.onboarding-welcome')
    : route('will-writing');
});

const menus = ref([
  {
    title: 'About us',
    itemsClass: 'font-medium',
    items: [
      {
        name: 'Octopus Legacy',
        icon: SparklesIcon,
        route: route('about'),
      },
      {
        name: 'Guides',
        icon: ChatBubbleLeftEllipsisIcon,
        route: route('advice.index'),
      },
      {
        name: 'Careers',
        icon: BriefcaseIcon,
        route: `${route('careers')}`,
      },
      {
        name: 'Contact Us',
        icon: PhoneIcon,
        route: route('contact'),
      },
    ],
  },
  {
    title: 'Plan for Death',
    itemsClass: 'font-bold',
    items: [
      {
        name: 'Will Writing',
        description: 'Write a will that’s tailored to you',
        icon: PencilIcon,
        route: willRoute.value,
      },
      {
        name: 'Life Insurance',
        description: 'Life Insurance for the 21st century',
        icon: ShieldCheckIcon,
        route: route('life-insurance'),
      },
      {
        name: 'Lasting Power of Attorney',
        description:
          'Appoint someone you trust to make \n' + 'decisions on your behalf',
        icon: DocumentTextIcon,
        route: route('lasting-power-of-attorney'),
      },
      {
        name: 'Legacy Box',
        description: 'Make life easier for the people you leave behind',
        icon: GiftIcon,
        route: route('fwig'),
      },
    ],
  },
  {
    title: 'Get support after loss',
    itemsClass: 'font-bold',
    items: [
      {
        name: 'Bereavement Support Hubs',
        description: 'Build a supportive community',
        icon: HeartIcon,
        route: route('bereavement'),
      },
      {
        name: 'Probate',
        description: 'Your guide through probate',
        icon: ScaleIcon,
        route: route('probate'),
      },
      {
        name: 'Dedicated Concierge',
        description: 'Personal support after loss',
        icon: PhoneIcon,
        route: route('death-concierge'),
      },
    ],
  },
]);

const store = useStore();
const isAuthenticated = store.getters['user/isAuthenticated'];
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.nav__button-group {
  @apply p-10 flex flex-col sm:flex-row sm:px-10 bg-white gap-2;
}

.nav {
  height: calc(100vh - 4rem);
  @apply bg-white w-full flex flex-col justify-between;
}

.nav__items {
  @apply p-5 flex flex-col gap-8;
}

.nav__items__item {
  @apply text-ol-dark-ink-200 text-2xl cursor-pointer flex;
}

.nav__where-to-start {
  @apply w-full sm:m-10 text-center sm:w-72 sm:h-72;
}

.nav__chevron {
  @apply my-auto fill-ol-dark-ink-200;
}
</style>
